// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2022_06-module--afterCaption--7cb28";
export var colWrapper = "maine_digest_2022_06-module--colWrapper--c1e38";
export var columnWrapper = "maine_digest_2022_06-module--columnWrapper--0d1f0";
export var dateline = "maine_digest_2022_06-module--dateline--011e0";
export var heading = "maine_digest_2022_06-module--heading--2e22e";
export var headingLogo = "maine_digest_2022_06-module--headingLogo--3dc26";
export var headingWrapper = "maine_digest_2022_06-module--headingWrapper--18883";
export var heroImage = "maine_digest_2022_06-module--heroImage--322f7";
export var instance = "maine_digest_2022_06-module--instance--d4286";
export var latestnewsarticleheadline = "maine_digest_2022_06-module--latestnewsarticleheadline--c0113";
export var leftCol = "maine_digest_2022_06-module--leftCol--da418";
export var maineDigest = "maine_digest_2022_06-module--maineDigest--89d1c";
export var photoCaption = "maine_digest_2022_06-module--photoCaption--cd5c7";
export var rightCol = "maine_digest_2022_06-module--rightCol--cf0e5";
export var subheading = "maine_digest_2022_06-module--subheading--6276a";
export var textWrapper = "maine_digest_2022_06-module--textWrapper--fefac";
export var whatsNew = "maine_digest_2022_06-module--whatsNew--e1bab";